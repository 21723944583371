import React, { useEffect } from 'react';
import styles from './Home.module.css';
// import loadBrevoWidget from './src/components';
import loadBrevoWidget from '../brevo'; // Adjust the path if necessary

const Home = () => {
  useEffect(() => {
    loadBrevoWidget(); // Load the Brevo widget when the component mounts
  }, []); // Empty array to ensure it runs only once

  return (
    <div className={styles.pageContainer}>
      
      <section className={styles.hero}>
        <h1>A Free Invoicing and POS Software</h1>
        <div className={styles.paragraph}>
          {/* You can add a description here */}
        </div>
        <div className={styles.imgContainer}>
          <img
            src={process.env.PUBLIC_URL + '/banner.png'}
            alt="invoicing-app"
          />
        </div>
      </section>

      {/* You can add the widget container here if needed */}
      <div id="brevo-widget-container"></div>
      
    </div>
  );
};

export default Home;
