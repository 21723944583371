// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner_loader__3ENkM {\n  border: 16px solid #f3f3f3;\n  border-radius: 50%;\n  border-top: 16px solid #ff3131;\n  width: 150px;\n  height: 150px; /* Safari */\n  animation: Spinner_spin__2cA5G 2s linear infinite;\n  \n}\n\n/* Safari */\n\n@keyframes Spinner_spin__2cA5G {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n\n.Spinner_container__3wPbR {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 100px;\n}", "",{"version":3,"sources":["webpack://src/components/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,8BAA8B;EAC9B,YAAY;EACZ,aAAa,EAC+B,WAAW;EACvD,iDAAkC;;AAEpC;;AAEA,WAAW;;AAUX;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB","sourcesContent":[".loader {\n  border: 16px solid #f3f3f3;\n  border-radius: 50%;\n  border-top: 16px solid #ff3131;\n  width: 150px;\n  height: 150px;\n  -webkit-animation: spin 2s linear infinite; /* Safari */\n  animation: spin 2s linear infinite;\n  \n}\n\n/* Safari */\n@-webkit-keyframes spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "Spinner_loader__3ENkM",
	"spin": "Spinner_spin__2cA5G",
	"container": "Spinner_container__3wPbR"
};
export default ___CSS_LOADER_EXPORT___;
