// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uploader_dropzone__2bqDD {\n  height: 4rem;\n  width: 100%;\n  margin: 0.5rem;\n  padding: 1rem;\n  border: 2px dashed rgb(182, 182, 182);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.4rem;\n  font-weight: 500;\n  cursor: pointer;\n}\n\n.Uploader_active__23MJs {\n  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);\n  background-color: #ff3131;\n  color: white;\n  border: 1px solid rgb(182, 182, 182);\n  border-radius: 5px;\n}", "",{"version":3,"sources":["webpack://src/components/Settings/Form/Uploader.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;EACd,aAAa;EACb,qCAAqC;EACrC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,4EAA4E;EAC5E,yBAAyB;EACzB,YAAY;EACZ,oCAAoC;EACpC,kBAAkB;AACpB","sourcesContent":[".dropzone {\n  height: 4rem;\n  width: 100%;\n  margin: 0.5rem;\n  padding: 1rem;\n  border: 2px dashed rgb(182, 182, 182);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.4rem;\n  font-weight: 500;\n  cursor: pointer;\n}\n\n.active {\n  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);\n  background-color: #ff3131;\n  color: white;\n  border: 1px solid rgb(182, 182, 182);\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "Uploader_dropzone__2bqDD",
	"active": "Uploader_active__23MJs"
};
export default ___CSS_LOADER_EXPORT___;
