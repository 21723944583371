import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: '#ffffff', // Button background color
    borderColor: '#ff3131', // Border color
    borderWidth: '1px', // Optional: Set border width
    borderStyle: 'solid', // Border style
    color: '#ff3131', // Button text color
    borderRadius: '8px', // Border radius for rounded corners
    '&:hover': {
      backgroundColor: '#e60000',
      color: '#ffffff' // Optional hover effect
    },
  },
}));
