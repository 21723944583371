// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Password_button__3vEFI {\n    padding: 9px 13px;\n    border-radius: 50px;\n    border: solid 1px #ff3131;\n    cursor: pointer;\n    font-size: 12px;\n    background-color: white;\n    margin: 8px;\n    color:#ff3131; \n    \n\n}\n\n.Password_button__3vEFI:hover {\n    background-color: #ff3131;\n    color: white;\n    border: solid 1px #ff3131;\n}\n\n.Password_buttons__3CYGF {\n    margin-top: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}", "",{"version":3,"sources":["webpack://src/components/Password/Password.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,eAAe;IACf,uBAAuB;IACvB,WAAW;IACX,aAAa;;;AAGjB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".button {\n    padding: 9px 13px;\n    border-radius: 50px;\n    border: solid 1px #ff3131;\n    cursor: pointer;\n    font-size: 12px;\n    background-color: white;\n    margin: 8px;\n    color:#ff3131; \n    \n\n}\n\n.button:hover {\n    background-color: #ff3131;\n    color: white;\n    border: solid 1px #ff3131;\n}\n\n.buttons {\n    margin-top: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Password_button__3vEFI",
	"buttons": "Password_buttons__3CYGF"
};
export default ___CSS_LOADER_EXPORT___;
