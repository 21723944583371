// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Clients_pageLayout__1lFQa {\n  margin-top: 60px;\n}", "",{"version":3,"sources":["webpack://src/components/Clients/Clients.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":[".pageLayout {\n  margin-top: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageLayout": "Clients_pageLayout__1lFQa"
};
export default ___CSS_LOADER_EXPORT___;
