// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footerText__2uHpc {\n    border-top: solid 1px rgb(219, 219, 219);\n    width: 100%;\n    margin: 0px auto;\n    text-align: center;\n    padding: 25px;\n    text-align: center;\n    background-color: #EEEEEE;\n  }\n\n  .Footer_footerLink__emsFw {\n    text-decoration: none;\n    color: #ff3131;\n  }", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB","sourcesContent":[".footerText {\n    border-top: solid 1px rgb(219, 219, 219);\n    width: 100%;\n    margin: 0px auto;\n    text-align: center;\n    padding: 25px;\n    text-align: center;\n    background-color: #EEEEEE;\n  }\n\n  .footerLink {\n    text-decoration: none;\n    color: #ff3131;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerText": "Footer_footerText__2uHpc",
	"footerLink": "Footer_footerLink__emsFw"
};
export default ___CSS_LOADER_EXPORT___;
