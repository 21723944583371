const loadBrevoWidget = () => {
    (function(d, w, c) {
      // Get the chatID from environment variables and format it
      // Assume this is a valid string like '66f9af4a743c13d5dc001cfa'
    
      w.BrevoConversationsID = '66f9af4a743c13d5dc001cfa'; // Adding single quotes around the chatID value
      
      w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
      };
  
      var s = d.createElement('script');
      s.async = true;
      s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, 'BrevoConversations');
  };
  
  export default loadBrevoWidget;